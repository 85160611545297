.error {
	&__svg {
		left: auto;
		width: 300px;
		max-width: 80vw;
		position: relative;
		margin: 0 auto 35px;
		transform: translate(0,0);
	}
}

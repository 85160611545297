.chart {
	&--buttons {
		button {
			margin: 0;
			border: 0;
			height: 40px;
			padding: 0 15px;
			overflow: hidden;
			min-width: 140px;
			line-height: 40px;
			position: relative;
			text-align: center;
			white-space: nowrap;
			transition: $transition;
			text-overflow: ellipsis;
			background-color: $white-color;

			@media(min-width: 1100px){
				padding: 0 35px;
			}

			&:before {
				top: 0;
				left: 50%;
				opacity: 0;
				width: 15px;
				content: "";
    			height: 15px;
				display: block;
				position: absolute;
				pointer-events: none;
				transition: $transition;
				background-color: $third-color;
				transform: translate(-50%, -50%) rotate(45deg);
			}

			&.btn-primary {
				color: $white-color;
				background-color: $third-color !important;

				&:before {
					opacity: 1;
				}

				&:hover {
					background-color: darken($third-color, 10%) !important;

					&:before {
						background-color: darken($third-color, 10%);
					}
				}
			}

		}
	}
}

.search-clear {
	top: 75px;
	right: 50px;
	padding: 10px;
	display: block;
	position: absolute;
}

#search {
	@media only screen and (max-width: 1024px) {
		width: 100% !important;
		margin: 15px 0px 15px 0px;
	}

	/*@media only screen and (max-width: 600px) {
		width: 100% !important;
		margin: 15px 0px 15px 0px;
	}*/
}
.lama__modal {
	left: 0;
	top: 80px;
	opacity: 0;
    width: 100%;
    z-index: 15;
    display: flex;
    position: fixed;
	visibility: hidden;
    align-items: center;
	pointer-events: none;
	transition: $transition;
    justify-content: center;
    height: calc(100vh - 80px);
    background-color: rgba($black-color, .85);
	
	&.shown {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
	}

	&--content {
		max-width: 500px;

		.accordion {
			margin: 0 -20px 20px;
		}
	}
}

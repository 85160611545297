.pagination {
	margin: 20px 0 0;
	border-radius: 0;
}

.page-link {
	border: none !important;
}

.page-item {
	margin-right: 5px;

	.page-link {
		height: 40px;
		padding: 0 8px;
		min-width: 40px;
		font-weight: 900;
		line-height: 40px;
		text-align: center;
		color: $primary-color;
		transition: $transition;
		border-radius: 0 !important;
		background-color: $gray-200-color;
		
		&:hover {
			background-color: darken($gray-200-color, 10%);
		}
	}
	
	&.active {
		.page-link {
			color: $primary-color;
			background-color: $secondary-color;
			
			&:hover {
				cursor: default;
				background-color: darken($secondary-color, 10%);
			}
		}
	}

	&.disabled {
		.page-link {
			color: $gray-400-color;
			background-color: $gray-200-color;

			&:hover {
				cursor: default;
				background-color: $gray-200-color;
			}
		}
	}
}
@media only screen and (max-width: 1024px) {
	thead, tfoot,
	.my_thead,
	.my_tfoot {
		display: none;
	}

	.sm-mb-3 {
		margin-bottom: 1rem !important;
	}

	.sm-mb-3:last-child {
		margin-bottom: 0 !important;
	}

	.md-show {
		display: block !important;
	}
}

@media only screen and (max-width: 600px) {
	thead, tfoot,
	.my_thead,
	.my_tfoot {
		display: none;
	}

	.sm-mb-3 {
		margin-bottom: 1rem !important;
	}

	.sm-mb-3:last-child {
		margin-bottom: 0 !important;
	}

	.sm-show {
		display: block !important;
	}
}

.notifyjs-bootstrap-success {
	color: $white-color !important;
	background-color: #28a745 !important;
}

.notifyjs-bootstrap-error {
	color: $white-color !important;
	background-color: #dc3545 !important;
}

.card {
	border: 0;
	border-radius: 0;

	&__number {
		font-size: 30px;
		font-weight: 900;
		white-space: nowrap;
		font-family: $headline-font;
		text-align: right;
	}

	&__body {
		&--flex {
			display: flex;
			align-items: center;

			&.reverse {
				flex-direction: row-reverse;

				&__number,
				&__text {
					width: 100%;
				}

				&__number {
					min-width: 25%;
				}

				&__text {
					max-width: 75%;
				}

				.card {
					&__number {
						text-align: right;
					}
				}
			}
		}
	}
}

.breadcrumb {
	font-size: 12px;
	font-weight: 600;
	border-radius: 0;
	position: relative;
	margin: -15px -15px 17px;
	
	@media(min-width: 1025px){
		padding-left: 66px;
	}

	@media only screen and (max-width: 600px) {
		display: none !important;
	}

	a {
		color: $primary-color;

		&:hover {
			color: $third-color;
		}
	}

	&__burger {
		top: 50%;
		left: 20px;
		padding: 0;
		width: 36px;
		height: 26px;
		display: none;
		cursor: pointer;
		position: absolute;
		align-items: center;
		justify-content: center;
		transform: translateY(-50%);
		background-color: $alt-color;

		@media(min-width: 1025px){
			display: flex;
		}

		.burger {
			margin: 0;
		}

		span {
			background-color: $white-color;
		}
	}
}

.flag-icon.flag-icon-squared {
	top: -1px;
	width: 20px;
	line-height: 20px;
}

.scroll-to-top {
	right: auto;
	bottom: auto;
	top: 0;
	left: 0;
	width: 90px;
	z-index: 91;
	height: 60px;
	line-height: 60px;
	transition: $transition;
	background-color: $alt-color;

	&:hover {
		background-color: $primary-color;
	}

	@media(max-width: 600px) {
		right: 0;
		top: auto;
		bottom: 0;
		left: auto;
		width: 60px;
	}
}

#search {
	min-width: 330px;
}

#wrapper {
	z-index: 89;
	position: relative;
	transition: $transition;
	
	@media(min-width: $sm){
		padding-left: 250px;

		.small--menu & {
			padding-left: 70px;
		}
	}

	#content-wrapper {
		padding-bottom: 50px;
		position: relative;
	}
}

.small--headline {
	margin-top: 35px;
}

.smallblock {
	width: 120px;
	text-align: right;
}

tr {
	border-bottom: 1px solid $background-color;
}

@media(min-width:1024px) {
	.preview--box {
		margin-top: -30px;
		padding-top: 30px;
	}
}

.row.ml-2-inherit {
	@media(max-width: $lg - 1){
		margin-left: 10px;
	}
}

.badge {
	padding: 4px 8px;
	font-weight: 600;
	border-radius: 0;
}

.clearable {
	position: relative;

	&__button {
		position: absolute;
		top: 11px;
		right: 11px;
		cursor: pointer;
	}
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $lightgray-color;
	transition: .4s ease;

	&:before {
		position: absolute;
		content: "";
		height: 26px;
		width: 26px;
		left: 4px;
		bottom: 4px;
		background-color: $white-color;
		transition: .4s;
	}

	.round {
		border-radius: 34px;

		&:before {
			border-radius: 50%;
		}
	}
}

input {
	&:checked {
		+.slider {
			background-color: $third-color;
		}
	}

	&:focus {
		+.slider {
			box-shadow: 0 0 1px $third-color;
		}
	}
}

input:checked+.slider:before {
	transform: translateX(26px);
}

.switch {
	width: 60px;
	height: 34px;
	position: relative;
	display: inline-block;

	/* Hide default HTML checkbox */
	input {
		width: 0;
		height: 0;
		opacity: 0;
	}
}
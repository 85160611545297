.vs {
	&__selected {
		border: 0;
		height: 28px;
		padding: 0 7px;
		line-height: 1;
		border-radius: 0;
		color: $primary-color;
		background-color: transparent;
		position: relative;
	
		button {
			position: static;
	
			svg {
				margin-left: 4px;
				fill: $primary-color;
				transition: $transition;
			}
	
			&:before {
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				content: "";
				z-index: -1;
				height: 100%;
				display: block;
				position: absolute;
				transition: $transition;
				background-color: $secondary-color;
			}
			
			&:hover {
				&:before {
					background-color: $not-white-color;
				}
	
				svg {
					margin-left: 4px;
					fill: $alt-color;
				}
			}
		}
	}

	&__dropdown {
		&-menu {
			max-height: 450px;
		}

		&-toggle {
			border-radius: 0;
		}

		&-option {
			transition: $transition;

			&:before {
				width: 1px;
				content: "";
				height: 11px;
				transition: $transition;
				display: inline-block;
			}

			&--selected {
				&:before {
					top: -1px;
					width: 6px;
					margin-right: 4px;
					margin-left: -10px;
					position: relative;
					border-style: solid;
					transform: rotate(45deg);
					border-width: 0 2px 2px 0;
					border-color: $success-color;
				}
			}

			&--highlight {
				background-color: $primary-color;
				color: $white-color;

				&:before {
					border-color: $white-color;
				}
			}
			
		}
	}
}

input[type="search"].vs__search {
	border: 0;
	padding: 0;
	height: 28px;
	min-height: 28px;
	line-height: 28px;
}
body .vdpr-datepicker {
	&__button {
		@extend .btn;
		@extend .btn-secondary;
		margin-left: 0 !important;

		&.vdpr-datepicker__button-submit {
			@extend .btn-primary;
		}
	}

	&__calendar {
		&-button-helper {
			width: 190px;
		}

		&-control {
			padding: 6px 0px 21px;

			&-prev,
			&-next {
				@extend .btn-icon;
				@extend .btn-info;
				height: 40px;
				border-radius: 0;
				border: 0;
			}
		}

		&-table {
			td {
				padding: 4px;
				line-height: 33px;
			}
		}
	}
}

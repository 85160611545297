.accordion {
	margin: 0;
	padding: 0;
	list-style: none;
	background-color: $gray-200-color;

	&--headline {
		cursor: pointer;
		font-weight: 900;
		padding: 10px 20px;
		position: relative;

		&:before {
			width: 8px;
			right: 20px;
			height: 8px;
			content: "";
			display: block;
			position: absolute;
			border-style: solid;
			top: calc(50% - 2px);
			transition: $transition;
			border-width: 0 2px 2px 0;
			border-color: $primary-color;
			transform: translateY(-50%) rotate(45deg);
		}

		&.open {
			&:before {
				top: calc(50% + 2px);
				transform: translateY(-50%) rotate(225deg);
			}
		}
	}

	&--content {
		display: none;
		padding: 0 20px 20px;
	}

	> li {
		&:not(:last-child) {
			border-bottom: 1px solid $white-color;
		}
	}
}

.header {
	&--logo {
		svg {
			max-width: 185px;
			fill: $white-color;
			transition: $transition;
		}

		a {
			&:hover {
				svg {
					fill: $secondary-color;
				}
			}
		}
	}
}

.lama {
	&__logo {
		top: 0;
		left: 50%;
		padding: 0;
		width: 100px;
		position: absolute;
		transform: translateX(-50%);

		@media(max-width: 600px) {
			left: 0;
			width: 80px;
			transform: translateX(0);
		}

		svg {
			circle {
				transition: $transition;
			}
		}

		&:hover {
			svg {
				circle {
					fill: $primary-hover-color !important;
				}
			}
		}
	}
}

.burger {
	width: 24px;
	margin: 6px 9px 0;
	position: relative;

	span {
		top: 50%;
		left: 50%;
		width: 24px;
		height: 2px;
		display: block;
		position: absolute;
		border-radius: 2px;
		background-color: $white-color;
		transform: translate(-50%, -50%);

		&:first-child {
			top: calc(50% - 6px);
		}

		&:last-child {
			top: calc(50% + 6px);
		}
	}
}

.pagesettings {
	&__wrap {
		position: relative;

		&--content {
			overflow: auto;
			padding: 0 15px;
			max-height: 0px;
			transition: $transition;
			margin: -16px -15px 25px;
			background-color: $not-white-color;

			&.opened {
				padding: 50px 15px 15px;
				max-height: 500px;
			}

			label {
				padding: 5px 10px;
				display: inline-block;
			}
		}
		
		&--toggle {
			top: 100%;
			right: 15px;
			cursor: pointer;
			padding: 5px 12px;
			position: absolute;
			transition: $transition;
			border-radius: 0 0 5px 5px;
			background-color: $not-white-color;
			
			&:hover {
				background-color: $secondary-color;
			}
		}
	}
}
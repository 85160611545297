.dealerstats {
	&__actions {
		padding: 20px;
		margin: -7px -31px 0;

		@media(min-width: $md){
			margin: -10px -20px 0;
		}
	}

	.field-input[data-v-5b500588] {
        font-size: 16px;
        border: 0 !important;
        border-radius: 0 !important;
    }

    .date-time-picker .lm-fs-12 {
        transition: .3s ease;
        font-size: 11px !important;
    }

	.custom-button {
		&.is-hover,
		&:hover {
			color: $primary-color !important;
			background-color: $lightgray-color !important;
		}

		&.is-selected {
			color: $white-color !important;
			background-color: $primary-color !important;
		}

		.custom-button-effect {
			display: none !important;
		}
	}

    .header-picker[data-v-6d49f11d] {
        background-color: $primary-color !important;
    }
    
    .datepicker-buttons-container {
		.datepicker-button {
			&.validate[data-v-601c6e79] {
				background-color: $third-color !important;
		
				&:hover {
					background-color: darken($third-color, 10%) !important;
				}
			}

			.datepicker-button-effect[data-v-601c6e79] {
				background: $white-color;
			}

			svg[data-v-601c6e79] {
				fill: $white-color;
			}
		}
	}

    .hide-input-vs {
		.vs__dropdown-toggle {
			min-height: 40px;
			background: $white-color;
			border-color: $primary-color;
		}

		input[type=search].vs__search {
			position: absolute;
			opacity: 0;
			pointer-events: none;
		}
	}

    .datepicker-button-effect {
        display: none !important;
    }

    .date-time-picker .justify-content-center {
        fill: $primary-color !important;
        color: $primary-color !important;
    }

    .datetimepicker {
        transform: translate(0, 0) !important;

		.datepicker[data-v-17c053f2] {
			left: -1px;
			border-radius: 0;
			top: -43px !important;
			width: calc(100% + 2px) !important;
			max-width: calc(100% + 2px) !important;
			min-width: calc(100% + 2px) !important;

			@media(max-width: $sm - 1){
				&:not(.inline) {
					bottom: auto !important;
					height: 55vh;
				}
			}
		}
    }

    .datepicker-container[data-v-7043ad7f] {
        width: 100%;
    }

	@media(max-width: $xl - 1) and (min-width: $sm){
		.datepicker-container {
			&.range {
				&.has-shortcuts {
					display: flex;
					flex-direction: column;
				}
			}

			.calendar {
				padding: 0 10px;
			}
		}
		
		.shortcuts-container {
			display: flex;
			flex-wrap: wrap;
			width: 100% !important;
			height: auto !important;
			max-width: 100% !important;

			button {
				height: auto !important;
				padding: 5px !important;
				margin: 0 5px !important;
				max-width: calc(33% - 10px);
			}
		}
	}
}

.billing-table {
	width: 100%;
	
	td {
		padding: 8px 0px;
	}
}

#updatePaymentMethod {
	width: 100%;
	//margin: 0 auto;
	max-width: 450px;

    padding: 25px;
	background-color: $not-white-color;
	
	input {
		width: 100%;
		padding: 0 12px;
		min-height: 40px;
		border: 0;
	}
}

#card-element {
    padding-left: 10px;
    background-color: $white-color;
}

#card-button {
	width: 100%;
}
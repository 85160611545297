input {
	&[type=checkbox] {
		&.item-to-select {
			visibility: hidden;
			position: absolute;

			&:before {
				content: url('/images/checkmark1.png');
				position: absolute;
				visibility: visible;
				width: 30px;
				height: 30px;
				background: #f2f2f2;
				padding: 0px;
				border-radius: 100%;
				margin-top: -15px;
				transition: all 1s;
			}

			&:checked {
				&:before {
					position: absolute;
					visibility: visible;
					margin-top: -15px;
					background: $success-color;
				}
			}
		}
	}
}

.input {
	&--radius {
		max-width: 150px;
	}
}

.dropdown {

	.butn,
	.btn {
		&.dropdown-toggle {
			box-shadow: 0 0px $middlegray-color;
			padding: 0px;
			height: 40px;
			width: 40px;
			transition: all 0.5s ease;

			&:after {
				display: none;
			}
		}

		&:hover {
			background: $middlegray-color;
			top: 0px;
		}

		&.btn-secondary {
			.dropdown-item {
				box-shadow: 0 0px $middlegray-color;
				padding: .75rem;
				background-color: $white-color;
				color: $middlegray-alt-color;
				margin-bottom: 0rem;
				border-top: 1px rgba($black-color, .15) solid;
				border-radius: 0rem 0rem 0.25rem 0.25rem;
				transition: all 0.5s ease;

				&:first-child {
					border-top: 0px $lightgray-color solid;
					border-radius: 0.25rem 0.25rem 0rem 0rem;
				}

				&:hover {
					color: $middlegray-alt-color;
					background-color: #f2f2f2;
				}
			}
		}
	}

	&-toggle {
		::after {
			border-top-color: $primary-hover-color;
			position: absolute;
			top: calc(100% - 1px);
			left: 50%;
			transform: translateX(-50%);
			margin-left: 0;
		}
	}
}

input.form-control,
textarea.form-control {
	border-radius: 0;
}

.input--look {
	border: 1px solid $primary-color;
}

input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="text"] {
	padding: 0 12px;
	min-height: 40px;
	width: 100%;
	display: block;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: $primary-color;
	background-clip: padding-box;
	background-color: $white-color;
	height: calc(1.5em + 0.75rem + 2px);
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

	&:disabled {
		color: $gray-400-color;
		background-color: $gray-200-color;
	}

	.vue-tags-input & {
		padding: 0;
		min-height: 0;
	}
}

.vue-tags-input {
	max-width: 100% !important;

	.ti-input {
		padding: 5px 0 !important;
		border-width: 0 0 1px !important;
		border-color: $primary-color !important;
	}

	.ti-tag {
		border-radius: 0 !important;
		padding: 8px 12px !important;
		color: $primary-color !important;
		background-color: $secondary-color !important;
	}

	.ti-deletion-mark {
		background-color: $alt-color !important;
	}
}

textarea {
	padding: 8px 12px;
}

.searchfilter {
	z-index: 4;
	height: 30px;
	position: relative;

	&__sort {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		border-radius: 4px;
		padding: 15px 15px 0;
		background-color: $not-white-color;

		@media(min-width: 768px) {
			padding: 35px 45px 20px;
		}

		&--extraline {
			@extend .searchfilter__sort;
			padding: 15px;
			border-radius: 0;
			text-align: center;
			align-content: center;
			margin: 3px -20px 20px;
			flex-direction: column;
			width: calc(100% + 40px);

			@media(min-width: 768px) {
				padding: 45px;
			}
		}

		&.no-border-radius {
			border-radius: 0;
		}

		>div {
			max-width: 20%;
			flex-basis: 20%;
			margin-bottom: 15px;
		}
	}

	&__form {
		left: 0;
		top: 40px;
		opacity: 0;
		z-index: 3;
		width: 100vw;
		overflow: auto;
		position: absolute;
		visibility: hidden;
		padding: 10px 0 15px;
		pointer-events: none;
		transition: $transition;
		max-width: calc(100vw - 30px);
		max-height: calc(75vh - 40px);
		background-color: $white-color;

		@media(min-width: $sm) {
			max-width: 350px;
		}

		@media(min-width: $md) {
			right: 0;
			left: auto;
		}

		>div {
			padding: 0 10px;
			position: relative;
		}

		form {
			display: flex;
			flex-direction: column;
		}

		.btn-primary {
			align-self: center;
		}

		&.opened {
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}

		&--headline {
			width: 100%;
			font-size: 22px;
			margin-top: 17px;
			font-weight: 900;
			margin-bottom: 0;
			padding-top: 17px;
			position: relative;
			margin-bottom: -10px;

			&__lined {
				&:before {
					top: -3px;
					left: -20px;
					height: 6px;
					content: "";
					position: absolute;
					width: calc(100% + 40px);
					background-color: #dfe2e6;
				}
			}
		}

		table {
			position: relative;
			
			tr {
				&:last-child {
					td,
					th {
						padding-bottom: 17px !important;
					}
				}
			}

			&:after {
				left: -10px;
				content: "";
				height: 6px;
				bottom: -3px;
				position: absolute;
				width: calc(100% + 20px);
				background-color: #dfe2e6;
			}
		}

		&--fade {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			z-index: 1;
			position: fixed;
			visibility: hidden;
			transition: $transition;
			pointer-events: none;
			background-color: rgba($primary-color, .16);

			&.opened {
				opacity: 1;
				visibility: visible;
				pointer-events: all;
			}
		}

		&--toggle {
			width: 40px;
			height: 40px;
			display: flex;
			cursor: pointer;
			border-radius: 4px;
			align-items: center;
			transition: $transition;
			color: $primary-color;
			justify-content: center;
			background-color: $not-white-color;

			@media(min-width: $md) {
				float: right;
			}

			&:hover {
				background-color: $secondary-color;
			}

			&.opened {
				color: $white-color;
				background-color: $primary-color;

				&:hover {
					color: $secondary-color;
				}
			}
		}
	}
}

#search_filter {
	tr {
		border: 0;
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		td {
			max-width: 100%;
			flex-basis: 100%;

			@media(min-width: $sm) {
				max-width: 50%;
				flex-basis: 50%;

				&:nth-child(2) {
					padding-right: 5px;
				}

				&:nth-child(3) {
					padding-left: 5px;
				}
			}

			&:first-child {
				max-width: 100%;
				flex-basis: 100%;
				font-size: .7rem;
				padding-top: 10px;
				padding-bottom: 0;

				label {
					margin-bottom: 0;
				}
			}
		}

		&:first-child {
			td {
				&:first-child {
					padding-top: 0;
				}
			}
		}
	}

	.form-control {
		padding-top: 0;
		min-height: auto;
		padding-bottom: 0;
		height: calc(1em + 0.75rem + 2px);
	}
}

.togglebox_flip,
.togglebox {
	display: none;

	+span {
		top: 7px;
		width: 40px;
		height: 25px;
		margin-right: 2px;
		position: relative;
		border-radius: 20px;
		display: inline-block;
		box-sizing: border-box;
		background-color: $secondary-color;
		border: 2px solid $white-color;
		transition: 0.3s cubic-bezier(0.79, 0.18, 0.24, 0.99);

		&:hover {
			cursor: pointer;
		}

		&::before {
			top: 50%;
			left: 2px;
			opacity: 1;
			width: 18px;
			content: "";
			height: 18px;
			position: absolute;
			border-radius: 20px;
			transform: translate(0, -50%);
			background-color: $white-color;
			box-shadow: 0 0 15px rgba(255, 255, 255, 0);
			transition: 0.1s cubic-bezier(0.79, 0.18, 0.24, 0.99);
		}
	}

	&:checked {
		+span {
			background-color: $third-color;

			&::before {
				left: calc(100% - 20px);
				background-color: $white-color;
			}
		}
	}

	&:disabled {
		+span {
			&:before {
				background-color: $middlegray-color;
				;
			}
		}

		&:checked {
			+span {
				background-color: $lightgray-color;
			}
		}
	}
}

.togglebox_flip {
	+span {
		background-color: $primary-color;

		&::before {
			left: calc(100% - 20px);
		}
	}

	&:checked {
		+span {
			background-color: $alt-color;

			&::before {
				left: 2px;
			}
		}
	}

	&.selected {
		+span {
			background-color: $third-color;
		}
	}
}

.radiobox,
.checkedbox {
	display: none;

	~.check {
		top: 4px;
		width: 20px;
		height: 20px;
		margin-right: 2px;
		position: relative;
		display: inline-block;
		box-sizing: border-box;
		border: 1px solid $primary-color;
		background-color: $white-color;
		transition: 0.3s cubic-bezier(0.79, 0.18, 0.24, 0.99);

		&:hover {
			cursor: pointer;
		}

		&::before {
			left: 50%;
			width: 7px;
			opacity: 0;
			content: "";
			height: 11px;
			position: absolute;
			border-style: solid;
			top: calc(50% - 1px);
			border-width: 0 2px 2px 0;
			border-color: $primary-color;
			transform: translate(-50%, -50%) rotate(45deg);
			transition: 0.1s cubic-bezier(0.79, 0.18, 0.24, 0.99);
		}
	}

	~.label {
		height: 20px;
	}

	&:checked {
		~.check {
			&::before {
				opacity: 1;
			}
		}
	}

	&:disabled {
		~.check {
			background-color: $gray-200-color;
		}

		&:checked {
			~.check {
				opacity: 1;

				&::before {
					border-color: $gray-400-color;
				}
			}

			~.label {
				color: $gray-400-color;
			}
		}
	}
}

.zipcode_checkbox {
	@extend .checkedbox;
}

.checkedbox_hide {
	@extend .checkedbox;

	~.check {
		&__shown {
			display: block;
		}
		&__hidden {
			display: none;
		}
	}

	&:checked {
		~.check {
			&__shown {
				display: none;
			}
			&__hidden {
				display: block;
			}
		}
	}
}

.radiobox {
	~.check {
		border-radius: 50%;

		&::before {
			top: 50%;
			width: 0;
			height: 0;
			border: none;
			border-radius: 50%;
			background-color: $primary-color;
		}
	}

	&:checked {
		~.check {
			&::before {
				width: 10px;
				height: 10px;
			}
		}
	}
}

textarea,
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="text"],
select.form-control {
	border-radius: 0;
	border: 1px solid $lightgray-color;
}

.search {
	&__field {
		&--wrap {
			width: 100%;
			position: relative;
			max-width: 350px;
		}

		&--field {
			width: 100%;
		}

		&--icon {
			top: 0;
			right: 0;
			width: 40px;
			height: 40px;
			display: flex;
			cursor: pointer;
			position: absolute;
			align-items: center;
			transition: $transition;
			justify-content: center;

			&:hover {
				background-color: $secondary-color;
			}
		}
	}
}

input[type="search"] {
	&.vs__search {
		padding-left: 10px !important;
	}
}

.validation-error {
	border: 1px solid $alt-color !important;
	background-color: rgba($alt-color, .15);
}

select {
	height: 40px;
	line-height: 40px;
	padding: 0 35px 0 10px;
	border: 1px solid $primary-color;

	&:focus {
		outline: 0;
		box-shadow: none !important;
		border-color: $primary-color !important;
	}
}

.form-group {
	label {
		font-weight: 600;
	}
}

.input--after {
	display: flex;
	font-weight: 900;
	align-items: center;
	text-transform: lowercase;

	input {
		margin-right: 10px;
	}
}

.tag-editor {
	padding: 5px 0;
	font-size: 16px;
	border-width: 0 0 1px;
	font-family: $body-font;
	border-color: $gray-400-color;
}

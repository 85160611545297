@import "~bootstrap/scss/bootstrap";
@import '~jquery-ui/themes/base/all.css';
@import '~@fortawesome/fontawesome-pro/css/all.min.css';
@import '~flag-icon-css/css/flag-icon.min.css';
@import '~jquery-tageditor/jquery.tag-editor.css';
@import '~daterangepicker/daterangepicker.css';
@import "~vue-select/src/scss/vue-select.scss";
@import "~vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

@import "utils/variables";

@import "layout/template";
@import "layout/header";
@import "layout/footer";
@import "layout/errors";

@import "base/all";

@import "base/base";
@import "base/backgrounds";
@import "base/typography";

@import "components/accordion";
@import "components/alerts";
@import "components/blocks";
@import "components/buttons";
@import "components/chart";
@import "components/form";
@import "components/lists";
@import "components/navigation";
@import "components/modal";
@import "components/slider";
@import "components/search";
@import "components/sidebar";
@import "components/pagination";
@import "components/select";
@import "components/table";
@import "components/datepicker";
@import "components/ellipsisloader";
@import "components/floating-labels";
@import "components/tooltips";

@import "pages/dashboard";
@import "pages/billing";
@import "pages/dealerstats";

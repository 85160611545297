.card-header {
	border: 0;
	padding: 15px 0;
	font-size: 20px;
	background-color: transparent;
	font-family: $headline-font;
}

.lama {
	&__geography {
		&--info {
			top: 0px;
			right: 0px;
			font-size: 14px;
			font-weight: 400;
			text-align: right;
			position: absolute;
		}
	}

	&__box {
		&--color {
			height: 15px;
			width: 15px;
			transform: translateY(2px);

			&.success {
				background-color: $success-color;
			}

			&.alt {
				background-color: $alt-color;
			}

			&.primary {
				background-color: $primary-color;
			}

			&.third {
				background-color: $third-color;
			}
		}
	}

	&__leads {
		&--number {
			width: 100%;
			line-height: 1;
			font-size: 126px;
			font-weight: 900;
			text-align: center;
		}

		&--small {
			font-size: 11px;
		}
	}
}

#lama__map {
	margin: -20px;
    width: calc(100% + 40px);

	.st0 {
		fill: $gray-200-color;
	}

	.tooltips {
		path {
			&:hover {
				stroke: $secondary-color;
				stroke-width: 2px;
			}
		}
	}
}
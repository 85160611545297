.alert {
	border: 0;
	font-size: 16px;
	border-radius: 0;
	margin: -17px -15px 20px;
	text-align: left !important;

	strong {
		display: block;
	}

	&-warning {
		background-color: $secondary-color;
		color: $primary-color;
	}

	&-danger {
		color: #c1004c;
		border-color: #ffe0ec;
		background-color: #ffe0ec;
	}

	&-dismissible {
		.close {
			bottom: 0;
			opacity: 1;
			color: $primary-color;
			transition: $transition;

			&:hover {
				opacity: .8;
			}
		}
	}
}
.sidebar {
	background-color: $secondary-color;

	&.navbar-nav {
		@media(max-width: 600px) {
			padding-top: 0;
		}
	}

	.nav-sublink {
		position: relative;

		> span {
			> span {
				padding-left: 5px;
				display: inline-block !important;

				&:before {
					top: 6px;
					content: "";
					margin-right: 2px;
					position: relative;
					transition: $transition;
					border: 3px solid transparent;
					border-top-color: $primary-color;
				}
			}
		}

		&:hover {
			> span {
				> span {
					&:before {
						border-top-color: $white-color;
					}
				}
			}
		}
	}

	.nav-item {
		.nav-link {
			color: $primary-color;
			
			&:focus {
				color: $primary-color;
			}

			&:hover {
				background-color: rgba($primary-hover-color, .5);
				color: $white-color;
			}
		}
		
		&.active {
			.nav-sublink {
				background-color: $primary-hover-color;
				color: $white-color;

				> span {
					> span {
						&:before {
							border-top-color: $white-color;
						}
					}
				}
			}
		}

		&.current {
			.nav-link {
				background-color: $primary-hover-color;
				color: $white-color;
			}
		}

		.sub-navigation {
			.nav-link {
				color: $primary-color;
			}

			.current {
				.nav-link {
					color: $white-color;
				}
			}

			.nav-item {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&.nav-item-danger {
			background-color: $third-color;
			
			.nav-link {
				color: $white-color;
			}
		}
	}

	&.toggled {
		.nav-item {
			.nav-link {
				transition: $transition;

				&:hover {
					background-color: rgba($primary-hover-color, .5);
					color: $white-color;
				}

				@media (min-width: 768px) {
					padding: 0.75rem 10px;
				}
			}
		}
	}
}

.sidebar.navbar-nav {
	max-height: calc(100vh - 56px);
	overflow: auto;
	
	@media(min-width: 768px){
		max-height: 100%;
		overflow: visible;
	}
}

#sidebarToggle {
	padding: 0 8px;
}
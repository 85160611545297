.floating-label {
	padding-top: 5px;
	position: relative;

	input {
		z-index: 2;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		padding-top: 15px;
		padding-left: 0px;
		position: relative;
		background-color: transparent;

		&:focus {
			outline: 0;
			box-shadow: none;
			background-color: transparent;
		}

		&.filled,
		&:focus {
			+ label {
				top: 2px;
				opacity: .6;
				font-size: 10px;
			}
		}
	}

	label {
		top: 21px;
		left: 0px;
		z-index: -1;
		font-size: 16px;
		position: absolute;
		pointer-events: none;
		transition: $transition;
	}
}

dl,
ol,
ul {
	padding-left: 1rem;
}

ul {
	&.nolist {
		margin: 0;
		padding: 0;
		list-style: none;
	}
}
body {
	padding-top: 80px;
	font-family: $body-font;
	color: $primary-color !important;
	background-color: $background-color;

	&.no-padding {
		padding-top: 0px;
	}
}

.thor-custom {
	background: none;
	right: 85px;
	z-index: 5000;
}

.thor-custom:focus,
.thor-custom:hover {
	color: none;
}

.thor-custom:hover {
	background: none;
}

.beta::after {
	content: "BETA";
	font-size: 10px;
	margin-left: 10px;
	vertical-align: text-top;
	line-height: 0.5em;
	font-weight: 400;
}

.action-icon {
	width: 20px;
	height: auto;
}

.table-responsive tr.hover:hover {
	background-color: $not-white-color;
}

.rbl-flex {
	display: flex;
}

.mobile-span {
	display: none;
	font-weight: 600;

	&:after {
		content: ":";
	}

	&.nocolon {
		&:after {
			content: "";
		}
	}

	@media only screen and (max-width: 1024px) {
		display: block;
	}

	@media only screen and (max-width: 600px) {
		display: block;
	}
}

.desktop-span {
	display: block;
	font-weight: 600;

	&:after {
		content: ":";
	}

	&.nocolon {
		&:after {
			content: "";
		}
	}

	@media only screen and (max-width: 1024px) {
		display: none;
	}

	@media only screen and (max-width: 600px) {
		display: none;
	}
}

.beautiful-hover {
	padding: 1rem;
	transition: 0.5s;
	margin: 0.5rem 0 0.5rem 0;
	background-color: $not-white-hover-color;

	&:hover {
		background-color: $not-white-color;
	}
}

.fillout {
	margin: 0 -35px;
	padding: 0 35px;
}

.loader {
	border: 4px solid #f3f3f3;
	border-top: 4px solid #3498db;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	animation: spin 1.5s linear infinite;
}

.information-box {
	border: 1px solid #bdc3c7;
    padding: 10px 0;

	ul {
		list-style: none;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
	padding-left: 10px;
	padding-right: 10px;
}

.row {
	margin-left: -10px;
	margin-right: -10px;
}

.main {
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 20px;
}

.box {
	width: 100%;
	padding: 20px;
	position: relative;
	margin-bottom: 20px;
	background-color: $white-color;

	& + & {
		padding-top: 0;
		margin-top: -20px;
	}
}

.border-bottom-light {
	border-bottom: 1px solid $secondary-color;
}

.ml {
	margin-left: 10px;
}

.mr {
	margin-right: 10px;
}

.products-headline {
	padding: 10px 0;
	font-weight: 900;
	margin-bottom: 10px;
	border-bottom: 1px solid $secondary-color;
}

.mailstatus {
	&-sent {
		color: #8BC1A9
	}

	&-delivered {
		color: #439b73
	}

	&-failed {
		color: #cd5053
	}

	&-temporary-failed {
		color: #e6a7a9
	}

	&-opened {
		color: #006be6
	}
}

.container-fluid {
	height: 100%;
}

.text-success {
	color: $third-color !important;
}

@for $i from 1 through 100 {
    .w-#{$i} {
        width: $i * 1% !important;
    }
}

.border-primary {
	border-color: $primary-color !important;
}

hr {
	&.darkline {
		margin-left: -20px;
		margin-right: -20px;
		border-color: $primary-color;
	}
}

.no-border-md {
	border-width: 0 !important;
	
	@media(min-width: $md){
		border-width: 1px !important;
	}
}
.butn {
	@extend .btn;
}

a.butn,
a.btn {
	text-decoration: none;
	color: $white-color;

	&.colored {
		color: $third-color;
	}
}

.butn,
.btn {
	border: 0;
	height: 40px;
	padding: 0 25px;
	box-shadow: none;
	font-weight: 600;
	line-height: 40px;
	text-align: center;
	transition: .1s ease;
	font-family: $headline-font;
	border-radius: 0px !important;

	&-multiple {
		height: auto;
		line-height: 1.4;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	& + & {
		margin-left: 10px;

		.dropdown-menu & {
			margin-left: 0;
		}
	}

	&.rounded-circle {
		border-radius: 50% !important;
	}

	&-primary {
		color: $white-color !important;
		background: $primary-color !important;

		&:not(:disabled) {
			&:hover {
				color: $white-color !important;
				background: lighten($primary-color, 10%) !important;
			}
		}
	}
	
	&-success {
		background: $success-color !important;

		&:hover {
			background: darken($success-color, 10%) !important;
		}
	}
	
	&-info-success,
	&-info-danger,
	&-info {
		color: $primary-color !important;
		background: $gray-200-color !important;
		
		&:hover {
			background: darken($gray-200-color, 10%) !important;
		}
	}

	&-info-danger {
		color: $alt-color !important;
		
		&:hover {
			color: $white-color !important;
			background: $alt-color !important;
		}
	}
	
	&-info-warning {
		color: $warning-color !important;
		
		&:hover {
			color: $white-color !important;
			background: $warning-color !important;
		}
	}
	
	&-info-success {
		color: $third-color !important;
	}
	
	&-info-selected {
		color: $white-color !important;
		background-color: $third-color !important;

		&:hover {
			background-color: darken($third-color, 10%) !important;
		}
	}
	
	&-secondary {
		color: $primary-color !important;
		background: $secondary-color !important;

		&:hover {
			background: darken($secondary-color, 10%) !important;
		}
	}
	
	&-danger {
		background: $alt-color !important;
		
		&:hover {
			background: darken($alt-color, 10%) !important;
		}
	}

	&-warning {
		color: $white-color;
		border-color: $warning-color !important;
		background-color: $warning-color !important;

		&:hover {
			color: $white-color;
			border-color: darken($warning-color, 10%) !important;
			background-color: darken($warning-color, 10%) !important;
		}
	}

	&-false {
		margin-right: 10px;
		color: $gray-400-color;
		background-color: transparent !important;
		border: 2px solid $gray-400-color !important;
		
		&:hover {
			color: $primary-color;
			border-color: $primary-color !important;
			background-color: $gray-200-color !important;
		}
	}

	&-icon {
		padding: 0;
		width: 40px;
		font-size: 18px;
	}
}

.btn-danger:hover,
.btn-info:hover,
.btn-warning:hover,
.btn-success:hover,
.btn-secondary:hover,
.btn-primary:hover {
	top: 0px;
	box-shadow: none;
	position: relative;
	z-index: 3;
}

#save-btn {
	@media only screen and (max-width: 1024px) {
		width: 48%;
		margin-right: 2%;
	}

	/*@media only screen and (max-width: 600px) {
		width: 48%;
		margin-right: 2%;
	}*/
}

#back-btn {
	@media only screen and (max-width: 1024px) {
		width: 48%;
		margin-left: 2%;
	}
}

.lama__btn {
	padding: 0 10px;
	font-size: 16px;
	color: $primary-color;
	letter-spacing: 0.3px;
	font-family: $headline-font;

	&.active {
		color: $alt-color;
		cursor: default !important;
	}

	&:hover {
		color: $secondary-color;
		transform: scale(1) !important;
	}

	&.focus,
	&:focus {
		box-shadow: none;
	}

	+.lama__btn {
		padding-left: 0;

		&:before {
			content: "|";
			padding-right: 7px;
			color: $primary-color !important;
		}
	}
}

.icon__btn {
	padding:0;
	border:0;
	background:none;
}

a.dropdown-item.btn {
	color: black;
}

button {
	&:focus {
		outline: 0 !important;
		box-shadow: none !important;
	}
}

#block-1,
#block-2,
#block-3 {
	display: inline-block;

	@media only screen and (max-width: 1024px) {
		display: block;
	}

	/*@media only screen and (max-width: 600px) {
		display: block;
	}*/
}

#block-2,
#block-3 {
	float: right;

	@media only screen and (max-width: 1024px) {
		float: none;
	}
	
	/*@media only screen and (max-width: 600px) {
		float: none;
	}*/
}

.block {
	@media only screen and (max-width: 1024px) {
		display: block;
		border-top: 1px solid $not-white-color;
		border-left: 1px solid $not-white-color;
		border-right: 1px solid $not-white-color;
	}

	/*@media only screen and (max-width: 600px) {
		display: block;
		border-top: 1px solid $not-white-color;
		border-left: 1px solid $not-white-color;
		border-right: 1px solid $not-white-color;
	}*/
}

.block-last {
	@media only screen and (max-width: 1024px) {
		display: block;
		margin-bottom: 30px;
		border-top: 1px solid $not-white-color;
		border-left: 1px solid $not-white-color;
		border-right: 1px solid $not-white-color;
		border-bottom: 1px solid $not-white-color;
	}

	/*@media only screen and (max-width: 600px) {
		display: block;
		margin-bottom: 30px;
		border-top: 1px solid $not-white-color;
		border-left: 1px solid $not-white-color;
		border-right: 1px solid $not-white-color;
		border-bottom: 1px solid $not-white-color;
	}*/
}

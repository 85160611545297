.light-bg,
.light-bg .card {
	background-color: $not-white-hover-color;
}

.lightblue-bg,
.lightblue-bg .card {
	background-color: $secondary-color;
}

.darkblue-bg,
.darkblue-bg .card {
	background-color: $primary-color;
	color: $white-color;
}

.green-bg {
	background-color: $success-color;
	color: $primary-color;
}

.bg-dark {
	background-color: $primary-color !important;
}

.bg-lama__alt {
	background-color: $secondary-color;
}

.bg-lama__third {
	background-color: $third-color;
}

.bg-lama__gray {
	background-color: $gray-200-color;
}

.bg-lama__notif {
	background-color: rgba($white-color, .9);
	z-index: 25;
	top: 40px;
	left: 0;
}

.lama__bg--red {
	display: flex;
	color: $white-color;
    align-items: center;
    flex-direction: column;
    justify-content: center;
	height: calc(100% - 20px);
	background-color: $alt-color;

	&.third {
		background-color: $third-color;
	}
}

.dropdown-menu {
	border: 0;
	margin: 0px;
	padding: 0px;
	border-radius: 0;

	.btn-secondary {
		padding-top: 0 !important;
		background: $white-color !important;

		&:hover {
			transform: scale(1);
			background-color: #dfdfdf !important;
		}
	}

	&.fixed-height {
		max-height: 500px;
		overflow: auto;
	}

	.form-group & {
		border: 1px solid $primary-color;
	}
}

.navbar-expand {
	background-color: $primary-color;

	.navbar-nav {
		height: 80px;
		margin-right: 0;

		li {
			height: 80px;
			line-height: 80px;
			transition: $transition;
			font-family: $headline-font;
			
			@media(min-width: $sm){
				padding: 0 1rem;
			}

			&.show {
				color: $primary-color;
				background-color: $secondary-color;

				> a {
					color: $primary-color;
				}
			}

			a {
				padding-top: 0;
				font-weight: 600;
				padding-bottom: 0;
				color: $white-color;

				i {
					top: 1px;
					font-size: 22px;
					position: relative;
					
					@media(min-width: $sm){
						margin-right: 10px;
					}
				}

				&.dropdown-item {
					height: 40px;
					line-height: 40px;
					font-weight: 600;
					color: $primary-color;
					transition: $transition;
					background-color: $secondary-color;
					
					&:hover {
						background-color: darken($secondary-color, 10%);
					}
				}

				&.dropdown-toggle {
					height: 100%;
					display: flex;
					padding: 0 15px;
					align-items: center;
					
					@media(min-width: $sm){
						padding: 0;
					}

					> span {
						flex-direction: column;
						line-height: 1.4;
						
						small {
							font-size: 10px;
						}
					}
				}
			}

			&:hover {
				background-color: $secondary-color;

				> a {
					color: $primary-color;
				}
			}
		}

		.dropdown-menu {
			right: 0;
			opacity: 0;
			width: 100%;
			visibility: hidden;
			transition: $transition;
			pointer-events: none;
			display: block !important;

			&.show {
				opacity: 1;
				visibility: visible;
				pointer-events: all;
				box-shadow: 0 0 5px rgba($black-color, .16);			
			}
		}
	}
}

.nav-tabs {
	.nav-link {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

nav {
	&.navbar {
		top: 0;
		left: 0;
		width: 100%;
		z-index: 90;
		height: 80px;
		padding: 0 1rem;
		padding-right: 0;
		position: fixed !important;
	}
}

.sub-navigation {
	max-height: 0;
	overflow: hidden;
	padding-bottom: 0;
	transition: $transition;
	color: $secondary-color;

	@media(min-width: $sm){
		.small--menu & {
			top: 0;
			left: 100%;
			position: absolute;
			background-color: $gray-200-color;

			a {
				padding: 10px 20px !important;
			}
		}
	}

	li {
		&:last-child {
			margin-bottom: 0;
		}
	}

	.nav-with-sub.active & {
		@media( min-width: 1025px){
			max-height: 250px;
			.small--menu & {
				max-height: 0px;
			}
		}
	}

	.nav-with-sub.opened & {
		max-height: 287px !important;
	}

	.nav-with-sub & {
		margin: 0;
		padding: 0;
		list-style: none;

		.nav-link {
			width: 100%;
			font-size: 14px;
			color: $darkgray-color;
			padding: 0 0px 8px 55px;
			transition: $transition;

			&:hover {
				color: $third-color;
			}
		}
	}
}

.nav-with-sub {
	position: relative;
}

#wrapper {
	.navbar-nav {
		&.sidebar {
			left: 0;
			z-index: 2;
			position: fixed;
		}
	}
}

.topnav-icon {
	&--button {
		width: 50px;
		height: 80px;
		display: flex;
		font-size: 22px;
		position: relative;
		color: $white-color;
		align-items: center;
		transition: $transition;
		justify-content: center;

		@media(min-width: $sm){
			width: 80px;
		}
		
		&__togglenav {
			display: none;
			margin-right: 4px;
			position: relative;
			
			@media(max-width: $sm){
				display: flex;
			}

			span {
				top: 50%;
				left: 50%;
				width: 25px;
				height: 2px;
				position: absolute;
				background-color: $white-color;
				transform: translate(-50%, -50%);

				&:first-child {
					top: calc(50% - 6px);
				}
				
				&:last-child {
					top: calc(50% + 6px);
				}
			}
		}

		@media(min-width: $md){
			&:hover {
				background-color: $secondary-color;
				color: $primary-color;

				.topnav-icon--hover {
					opacity: 1;
					visibility: visible;
					pointer-events: all;
				}
			}
		}
	}

	&--hover {
		right: 0;
		top: 100%;
		opacity: 0;
		width: 100vw;
		max-width: 350px;
		padding: 20px 35px;
		position: absolute;
		visibility: hidden;
		text-align: center;
		transition: $transition;
		pointer-events: none;
		color: $primary-color;
		background-color: $secondary-color;
		box-shadow: 0 0 5px rgba($black-color, .16);

		a {
			display: block;
			font-weight: 900;
			margin-bottom: 35px;
			color: $primary-color;

			&:hover {
				text-decoration: none;
				color: $primary-hover-color;
			}
		}
	}

	&--content {
		p {
			font-size: 16px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&--logo {
		width: 100%;
		margin-top: 10px;
		text-align: center;

		svg {
			fill: $primary-color;
			max-width: 80%;
		}
	}
}


.navigation {
	&__sidebar {
		left: 0;
		top: 80px;
		z-index: 14;
		width: 100vw;
		display: flex;
		position: fixed;
		flex-direction: column;
		transition: $transition;
		height: calc(100vh - 80px);
		transform: translateX(-100%);
		justify-content: space-between;
		background-color: $gray-200-color;
		
		.slideout--menu & {
			transform: translateX(0%);
		}
		
		
		@media(min-width: $sm){
			width: 250px;
			transform: translateX(0%);

			.small--menu & {
				width: 70px;
			}
		}

		&--menu {
			margin: 0;
			padding: 0;
			display: flex;
			list-style: none;
			flex-direction: column;

			>li {
				transition: $transition;
				border-left: 4px solid transparent;

				&.active,
				&.current {
					border-left-color: $alt-color;
				}

				>a {
					height: 48px;
					line-height: 48px;
					padding: 0 20px 0 55px;
					color: $darkgray-color;
					position: relative;
		
					i {
						top: 50%;
						left: 30px;
						font-size: 20px;
						position: absolute;
						transform: translate(-50%, -50%);
					}

					span {
						overflow: hidden;
						max-width: 250px;
						display: inline-block;
						transition: $transition;

						@media(min-width: $sm){
							.small--menu & {
								max-width: 0;
							}
						}
					}
				}

				&:hover {
					background-color: rgba($darkgray-color, .1);
					border-left-color: rgba($darkgray-color, .1);
				}
			}

			.nav-item {
				a {
					white-space: nowrap;
				}

				&.active,
				&.current {
					>a {
						color: $alt-color;
					}
				}
			}
		}

		&--footer {
			padding: 10px;
			font-size: 12px;
			font-weight: 600;
			text-align: center;
			color: $white-color;
			white-space: nowrap;
			background-color: $darkgray-color;

			span {
				overflow: hidden;
				max-width: 100px;
				white-space: nowrap;
				display: inline-block;
				transition: $transition;
				
				&.hidesmall {
					@media(min-width: $sm){
						.small--menu & {
							max-width: 0;
						}
					}
				}
			}
		}
	}
}

.nav-sublink {
	position: relative;

	&:before {
		top: 21px;
		opacity: 1;
		right: 20px;
		width: 10px;
		content: "";
		height: 10px;
		display: block;
		position: absolute;
		border-style: solid;
		pointer-events: none;
		transition: $transition;
		border-width: 0 1px 1px 0;
		border-color: $darkgray-color;
		transform: translate(-50%, -50%) rotate(45deg);

		.active &,
		.current & {
			border-color: $alt-color;
		}

		@media(min-width: $sm){
			.small--menu & {
				top: 24px;
				right: 12px;
				transform: translate(-50%,-50%) rotate(-45deg) scale(0.7);
			}
		}
	}
}

.little--menu {
	@extend .small--menu;
}
$black-color:				#000000;
$white-color:				#ffffff;
$not-white-color:			#e9ecef;
$not-white-hover-color:		#f8f9fa;

$primary-color:				#203554;
$primary-hover-color:		#364b6a;
$secondary-color:			#b8d2e3;
$alt-color:					#e72369;
$third-color:				#2196F3;
$bluegray-color:			$secondary-color;

$middlegray-color:			#565e64;
$middlegray-alt-color:		#495057;
$lightgray-color:			#cccccc;
$warning-color:				#ffc107;

$darkgray-color:			#292929;
$gray-200-color:			#E6E6E6;
$gray-400-color:			#AAAAAA;

$success-color:				#23e7a3;

$background-color:			#f4f4f4;

$sm:						768px;
$md:						1024px;
$lg:						1200px;
$xl:						1400px;

$body-font:					"Montserrat", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$headline-font:				"Montserrat", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

$transition:				.3s ease;

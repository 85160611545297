.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 900;
	margin: 0 0 20px;
	letter-spacing: 0.3px;
	font-family: $headline-font;
}

h2, .h2 {
	font-size: 30px;
}

h3, .h3 {
	font-size: 20px;
}

h4, .h4 {
	font-size: 18px;
	font-weight: 600
}

h5, .h5 {
	font-size: 16px;
	font-weight: 600
}

table {
	&.searchable-table,
	&.lama__table {
		width: 100%;

		th, td {
			@media(min-width: $md){
				&:first-child {
					padding-left: 0;
				}
				
				&:last-child {
					padding-right: 0;
				}
			}
		}

		thead {
			@extend .my_thead;
			padding-bottom: 5px;
			
			tr {
				border-bottom: 1px solid $secondary-color;
			}
		}
		
		tbody {
			font-size: 14px;
			padding-top: 5px;
			border-bottom: 1px solid $secondary-color;

			select {
				@media(min-width: $lg){
					font-size: 14px;
				}
			}

			tr {
				transition: $transition;
				border-bottom: 2px solid $primary-color;

				@media( min-width: $md + 1px ){
					border-bottom: 0;
				}

				&:hover:not(.loading-tr) {
					background-color: darken($white-color, 2.5%);
				}

				#preview & {
					&:hover {
						background-color: initial;
					}
				}

				td {
					min-height: 32px;

					&:last-child {
						@media(max-width: 768px){
							text-align: left !important;
						}
					}
				}
			}
		}

		tfoot {
			@extend .my_tfoot;
			padding-top: 5px;
		}

		th,
		td {
			@extend .block;
			padding: 5px 10px;
		}

		&--tall {
			@extend .lama__table;

			@media(min-width: $md){
				td {
					height: 50px;
					vertical-align: middle;
				}
			}
		}
	}
}

.table {
	@extend table;
}

.thead {
	@extend thead;
}

.tbody {
	@extend tbody;
}

.tr {
	@extend tr;
}

.th {
	@extend th;
}

.td {
	@extend td;
}
